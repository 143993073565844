import React from "react";
import hp from "../../assets/HP.svg";
import intel from "../../assets/Intel.svg";
import win11 from "../../assets/Win11.svg";
import z from "../../assets/Z.svg";
import mocktail from "../../assets/mocktail.png";
import shortTemper from "../../assets/a-shot-of-temper.png";
import balancedBliss from "../../assets/balanced-bliss.png";
import cheerfulCherry from "../../assets/cheerful-cherry-mix.png";
import coolCucumber from "../../assets/coconut-in-disguise.png";
import eurekaTwist from "../../assets/eureka-twist.png";
import Express from "../../assets/martini.png";
import phantom from "../../assets/fear-phantom.png";
import fiery from "../../assets/fieryfury.png";
import high from "../../assets/happy-high-ball.png";
import joyfull from "../../assets/joyful-gel-up.png";
import melancholy from "../../assets/melancholy-apple.png";
import neutral from "../../assets/neutral-nector.png";
import sorrowful from "../../assets/sorrowful-serenade.png";
import spiceOfLuck from "../../assets/spice-of-luck.png";
import Unexpected from "../../assets/unexpected-twist.png";
import { useNavigate } from "react-router-dom";

function Result({ drink }) {
  let backgroundImage;
  let line = `Have a sip of "${drink}" and enjoy the moment!`;
  const navigate = useNavigate();

  switch (drink) {
    case "mocktail":
      backgroundImage = mocktail;
      break;
    case "A Shot of Temper":
      line = "Anger detected! Unleash the heat with ‘A Shot of Temper’";
      backgroundImage = shortTemper;
      break;
    case "Balanced Bliss":
      line =
        "In a neutral state of mind? Sip on a Balanced Bliss Cocktail to bring a perfect equilibrium to your vibe!";
      backgroundImage = balancedBliss;
      break;
    case "Cheerful Cherry Bliss":
      line =
        "Got a smile on your face? Enjoy a Cheerful Cherry Mix to keep the good vibes flowing!";
      backgroundImage = cheerfulCherry;
      break;
    case "A Coconut in Disguise":
      line =
        "Disgusted? Refresh your palate with a Coconut in Disguise Cocktail.";
      backgroundImage = coolCucumber;
      break;
    case "Eureka Twist":
      line = "Caught off guard? Celebrate your surprise with our Eureka Twist.";
      backgroundImage = eurekaTwist;
      break;
    case "Express Your Martini":
      line = "Scared? Our Express Your Martini is just what you need.";
      backgroundImage = Express;
      break;
    case "Fear Phantom":
      line = "Scared? Our Express Your Martini is just what you need.";
      backgroundImage = phantom;
      break;
    case "Fiery Fury":
      line = "Feeling angry? Try our ‘Fiery Fury Cocktail’!";
      backgroundImage = fiery;
      break;
    case "Happy High- Ball":
      line =
        "In a great mood? Our Happy High-Ball is just the drink to lift your spirits even higher!";
      backgroundImage = high;
      break;
    case "Joyful Gel Up":
      line =
        "In a joyful mood? Sip on a Joyful Gel Up and keep the happiness flowing with every sip!";
      backgroundImage = joyfull;
      break;
    case "Melancholy Apple":
      line = "Feeling blue? Our Melancholy Apple is here to lift your spirits!";
      backgroundImage = melancholy;
      break;
    case "Neutral Nectar":
      line =
        "Feeling Neutral? Refresh yourself with our Neutral Nectar, perfectly balanced to match your vibe!";
      backgroundImage = neutral;
      break;
    case "Sorrowful Serenade":
      line =
        "You’re feeling down, sip on a Sorrowful Serenade and find a little solace in every glass.";
      backgroundImage = sorrowful;
      break;
    case "Spice of luck":
      line =
        "On cloud nine? Let our Spice of Luck cocktail add a touch of magic to your high spirits!";
      backgroundImage = spiceOfLuck;
      break;
    case "Unexpected Twist":
      line =
        "Surprised? Sip on an Unexpected Twist Cocktail and embrace the surprise with every delightful sip!";
      backgroundImage = Unexpected;
      break;

    default:
      backgroundImage = mocktail;
  }

  return (
    <div className="d-flex full-height dir-column">
      <div
        className="d-flex"
        onTouchStart={() => {
          navigate("/mood");
          window.location.reload();
        }}
        onClick={() => {
          navigate("/mood");
          window.location.reload();
        }}
      >
        <img src={hp} alt="" id="hp" />
        <img src={intel} alt="" id="intel" />
        <div
          style={{
            width: "1px",
            height: "61.781px",
            backgroundColor: "#FFF",
          }}
        ></div>
        <img src={win11} alt="" />
      </div>
      <img src={z} alt="" id="z" style={{ marginTop: "3vh" }} />
      <div
        style={{
          height: "77vh",
          paddingTop: "9%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h1
          style={{
            fontStyle: "italic",
            textDecoration: "underline",
            marginBottom: "5vh",
            fontSize: "62px",
            marginLeft: "64px",
            marginRight: "64px",
            lineHeight: "60px",
          }}
        >
          {`"${line}"`}
        </h1>
        <div
          style={{
            maxHeight: "50vh",
            maxWidth: "80%",
            width: "80%",
            margin: "auto",
          }}
        >
          <img
            src={backgroundImage}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Result;
