import axios from "axios";
import hp from "../../assets/HP.svg";
import intel from "../../assets/Intel.svg";
import win11 from "../../assets/Win11.svg";
import { useRef, useState, useEffect, useCallback } from "react";
import { convertBase64ToFile, supabase } from "../../utils";
import bg from "../../assets/sketchBg.jpg";
import { Select, Slider } from "antd";
import "./style.css";
import resetbtn from "../../assets/reset.svg";
import eraserbtn from "../../assets/eraser.svg";
import { colorArray } from "../../utils";
import Scanning from "../../Component/Scan/Scanning";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { SketchToAiService } from "../../Services/SketchToAiService";
const Canvas = () => {
  const location = useLocation();
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const [data, setData] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [strokeColor, setStrokeColor] = useState("black");
  const [isErasing, setIsErasing] = useState(false);
  const [eraserSize, setEraserSize] = useState(20);
  const [brushSize, setBrushSize] = useState(15);
  const [loading, setLoading] = useState(false);
  const [drawingStyle, setDrawingStyle] = useState("Anime");
  const [userImage, setUserImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const img = new Image();
    img.src = bg;

    img.onload = () => {
      const size = Math.min(window.innerWidth, window.innerHeight);
      canvas.width = size * 2;
      canvas.height = size * 2;
      canvas.style.width = `${size}px`;
      canvas.style.height = `${size}px`;
      canvas.style.border = "5px solid black";

      context.scale(2, 2);
      context.lineCap = "round";
      context.strokeStyle = strokeColor;
      context.lineWidth = brushSize;
      context.drawImage(img, 0, 0, size, size); // Draw the background image
      contextRef.current = context;
    };
  }, []);
  const handleBrushStroke = (val) => {
    setBrushSize(val);
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.lineWidth = val;
  };
  const startDrawing = (event) => {
    event.preventDefault();
    const { offsetX, offsetY } = getEventCoords(event);
    if (isErasing) {
      erase(offsetX, offsetY);
    } else {
      contextRef.current.beginPath();
      contextRef.current.moveTo(offsetX, offsetY);
      setIsDrawing(true);
    }
  };

  const finishDrawing = () => {
    if (!isErasing) {
      contextRef.current.closePath();
      setIsDrawing(false);
    }
  };

  const draw = useCallback(
    (event) => {
      event.preventDefault();
      if (!isDrawing && !isErasing) return;

      const { offsetX, offsetY } = getEventCoords(event);
      if (isErasing) {
        erase(offsetX, offsetY);
      } else {
        contextRef.current.lineTo(offsetX, offsetY);
        contextRef.current.stroke();
      }
    },
    [isDrawing, isErasing],
  );

  useEffect(() => {
    if (isDrawing || isErasing) {
      const drawSmoothly = (event) => draw(event);
      document.addEventListener("mousemove", drawSmoothly);
      document.addEventListener("touchmove", drawSmoothly);
      return () => {
        document.removeEventListener("mousemove", drawSmoothly);
        document.removeEventListener("touchmove", drawSmoothly);
      };
    }
  }, [isDrawing, isErasing, draw]);

  const getEventCoords = (event) => {
    if (event.touches && event.touches[0]) {
      const rect = event.target.getBoundingClientRect();
      return {
        offsetX: event.touches[0].clientX - rect.left,
        offsetY: event.touches[0].clientY - rect.top,
      };
    } else {
      return {
        offsetX: event.nativeEvent.offsetX,
        offsetY: event.nativeEvent.offsetY,
      };
    }
  };

  const erase = (offsetX, offsetY) => {
    const context = contextRef.current;
    context.clearRect(
      offsetX - eraserSize / 2,
      offsetY - eraserSize / 2,
      eraserSize,
      eraserSize,
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    const canvas = canvasRef.current;
    const dataUrl = canvas.toDataURL("image/jpeg");
    const image1 = convertBase64ToFile(dataUrl, `image.jpeg`);
    setUserImage(image1);

    const formData = new FormData();
    formData.append("image", image1);
    formData.append("prompt", prompt);
    formData.append("style", "dynamic");

    const result = await axios.post(
      "https://df.tagglabs.in/fontDetect",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    if (result?.data?.result) {
      const { data, error } = await supabase
        .from("Sketch-to-ai-images")
        .insert([{ image: result?.data?.result }]);
      setData(result?.data?.result);
      setLoading(false);
    } else {
      toast.error("Profane content detected. Please try again.");
    }
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = contextRef.current;
    const img = new Image();
    img.src = bg;

    img.onload = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      const size = Math.min(window.innerWidth, window.innerHeight);
      context.drawImage(img, 0, 0, size, size); // Redraw the background image
    };
  };
  const handleChange = (value) => {
    setDrawingStyle(value);
  };
  const handleShare = async () => {
    const locationData = location?.state?.result;
    await SketchToAiService({
      userid: locationData?.userId,
      image: data,
      userImage: userImage,
    });
    navigate("/sketch/startView");
  };
  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Scanning heading="Generating" />
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: "black", height: "100vh" }}>
      {data ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div className="d-flex">
            <img src={hp} alt="" id="hp" />
            <img src={intel} alt="" id="intel" />
            <div
              style={{
                width: "4px",
                height: "61.781px",
                backgroundColor: "#FFF",
              }}
            ></div>
            <img src={win11} alt="" />
          </div>
          <button className="btn" onClick={handleShare}>
            Share
          </button>
        </div>
      ) : (
        <div className="d-flex">
          <div className="d-flex">
            <canvas
              onMouseDown={startDrawing}
              onMouseUp={finishDrawing}
              onMouseLeave={finishDrawing}
              onTouchStart={startDrawing}
              onTouchEnd={finishDrawing}
              onTouchCancel={finishDrawing}
              ref={canvasRef}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
              height: "97vh",
              borderRadius: "10px",
              margin: "10px",
              flex: "1",
              // border: "2px solid white",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // border: "2px solid red",
                width: "100%",
              }}
            >
              <div
                style={{
                  height: "300px",
                  // border: "2px solid red",
                }}
              >
                {colorArray.map((item) => (
                  <button
                    key={item}
                    style={{
                      backgroundColor: item,
                      width: "40px",
                      height: "40px",
                      margin: "5px 5px",
                      borderRadius: "50%",
                    }}
                    onClick={() => {
                      const canvas = canvasRef.current;
                      const context = canvas.getContext("2d");
                      context.strokeStyle = item;
                      setStrokeColor(item);
                      setIsErasing(false);
                    }}
                  ></button>
                ))}
              </div>

              <div
                style={{
                  marginLeft: "10px",
                }}
              >
                <h4 style={{ color: "white" }}>Brush Stoke</h4>
                <Slider
                  value={brushSize}
                  onChange={handleBrushStroke}
                  styles={{
                    width: "10px",
                    track: {
                      background: "rgba(0, 150, 214, 0.5)",
                    },
                    rail: {
                      background: "GREY",
                    },
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
                width: "100%",
                // border: "2px solid red",
                flex: "1",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Select
                  size="middle"
                  defaultValue={drawingStyle}
                  onChange={handleChange}
                  style={{
                    width: "75%",
                  }}
                  className="custom-select"
                  options={[
                    { value: "Anime", label: "Anime" },
                    { value: "Cinematic", label: "Cinematic" },
                    { value: "Concept Art", label: "Concept Art" },
                    { value: "Dynamic", label: "Dynamic" },
                    { value: "Environment", label: "Environment" },
                    { value: "Fantasy Art", label: "Fantasy Art" },
                    { value: "Painting", label: "Painting" },
                    { value: "Photography", label: "Photography" },
                    { value: "Product", label: "Product" },
                    { value: "Raytraced", label: "Raytraced" },
                    { value: "Sketch B/W", label: "Sketch B/W" },
                    { value: "Sketch Color", label: "Sketch Color" },
                    { value: "Vibrant", label: "Vibrant" },
                  ]}
                />

                <div onClick={clearCanvas}>
                  <img src={resetbtn} alt="reset" />
                </div>
                <div
                  onClick={() => {
                    const canvas = canvasRef.current;
                    const context = canvas.getContext("2d");
                    context.strokeStyle = "white";
                  }}
                >
                  <img src={eraserbtn} alt="erase" />
                </div>
              </div>
              <textarea
                value={prompt}
                placeholder="write a prompt..."
                onChange={(e) => setPrompt(e.target.value)}
                style={{
                  background: "rgba(0, 150, 214, 0.5)",
                  borderRadius: "10px",
                  width: "100%",
                  height: "100px",
                  border: "1px solid white",
                  color: "white",
                  paddingLeft: "10px",
                  paddingTop: "10px",
                }}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  onClick={handleSubmit}
                  style={{
                    background: "rgba(0, 150, 214, 0.5)",
                    borderRadius: "10px",
                    width: "40%",
                    border: "1px solid white",
                    color: "white",
                    height: "40px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Canvas;
