import React, { useEffect, useState } from "react";
import hp from "../../assets/HP.svg";
import intel from "../../assets/Intel.svg";
import win11 from "../../assets/Win11.svg";
import { supabase } from "../../utils";
function ResultScreen() {
  const [image, setImage] = useState();
  const handleInserts = (payload) => {
    setImage(payload?.new?.image);
  };
  useEffect(() => {
    const handleUserInserts = async () => {
      const userChannel = supabase.channel("Sketch to AI");

      userChannel.on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "Sketch-to-ai-images" },
        handleInserts,
      );

      await userChannel.subscribe();

      return () => {
        userChannel.unsubscribe();
      };
    };

    handleUserInserts();
  }, []);
  return (
    <div>
      <div className="d-flex">
        <img src={hp} alt="" id="hp" />
        <img src={intel} alt="" id="intel" />
        <div
          style={{
            width: "4px",
            height: "61.781px",
            backgroundColor: "#FFF",
          }}
        ></div>
        <img src={win11} alt="" />
      </div>
      <div
        style={{
          marginTop: "50px",
          height: "71vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={image} style={{ height: "100%" }} />
      </div>
    </div>
  );
}

export default ResultScreen;
