import React, { useEffect, useRef, useState } from "react";
import CustomWebcam from "../../../Component/WebcamComponent";
import hp from "../../../assets/HP.svg";
import intel from "../../../assets/Intel.svg";
import win11 from "../../../assets/Win11.svg";
import "./style.css";
import smile from "../../../assets/smile.gif";
import Scanning from "../../../Component/Scan/Scanning";
import moodloader from "../../../assets/mood-loader.webm";
import { ExpressionDetectionService } from "../../../Services/MoodDetectionService";
import Result from "../Result";
import CountDown from "../../../Component/CountDown/CountDown";
import {
  convertWebPToPng,
  convertBase64ToFile,
  GenerateRandomNumber,
} from "../../../utils";
import MoodResult from "./MoodResult";
import { useNavigate } from "react-router-dom";

function Detection() {
  const [capturedImage, setCapturedImage] = useState(null);
  const webcamRef = useRef(null);
  const [result, setResult] = useState(null);
  const navigate = useNavigate();

  const captureImage = async () => {
    try {
      const imageSrc = await webcamRef.current.getScreenshot();
      let imageFile;
      if (imageSrc.startsWith("data:image/webp")) {
        const pngBase64 = await convertWebPToPng(imageSrc);
        imageFile = await convertBase64ToFile(pngBase64, "capturedImage.png");
      } else {
        imageFile = await convertBase64ToFile(imageSrc, "capturedImage.png");
      }

      const Result = await ExpressionDetectionService({
        image: imageFile,
      });

      setResult(Result);
    } catch (error) {
      const value = GenerateRandomNumber(1, 2);
      setResult({
        mood: "CALM",
        drink: "Neutral Nectar",
        value: value,
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      captureImage();
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (result) {
      setTimeout(() => {
        const element = document.getElementById("result");
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }

        setTimeout(() => {
          navigate("/mood");
        }, 10000);
      }, 3000);
    }
  }, [result]);
  return (
    <>
      <div
        className="d-flex full-height dir-column"
        style={{ justifyContent: "flex-start" }}
      >
        <div className="d-flex">
          <img src={hp} alt="" id="hp" />
          <img src={intel} alt="" id="intel" />
          <div
            style={{
              width: "1px",
              height: "61.781px",
              backgroundColor: "#FFF",
            }}
          ></div>
          <img src={win11} alt="" />
        </div>
        <h1>Let AI detect your mood!</h1>
        <h1>Please ensure your face is prominent</h1>
        <div
          style={{
            marginTop: "5%",
            position: "relative",
          }}
        >
          <div
            style={{
              boxSizing: "border-box",
              position: "absolute",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              width: "100%",
              flexDirection: "column",
              padding: "20px",
            }}
          >
            {/* <Scanning heading="Scanning" /> */}
          </div>
          <CustomWebcam height={700} width={920} webcamRef={webcamRef} />
        </div>

        {/* {capturedImage ? ( */}
        <div
          style={{
            marginTop: "4%",
            width: "100%",
            gap: "50px",
          }}
          className="d-flex dir-column"
        >
          <h1>Your Current Mood is...</h1>
          <div>
            <div
              style={{
                height: "20vh",
                width: "100%",
                position: "relative",
                overflow: "hidden",
              }}
            >
              {/* <img src={smile} alt="Animated GIF" /> */}
              {result ? (
                <MoodResult result={result} />
              ) : (
                <video
                  autoPlay
                  loop
                  muted
                  style={{ width: "100%", top: "-24%", position: "relative" }}
                >
                  <source src={moodloader} type="video/webm" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>
          {result && (
            <div className="box">
              <h1>Hmm...</h1>
              <h1>
                <span style={{ textTransform: "capitalize" }}>
                  {" "}
                  {result?.mood.toLowerCase()}
                </span>{" "}
                mood detected!
              </h1>
            </div>
          )}
        </div>
        {/* ) : (
          <div style={{ height: "100%", paddingTop: "13vh" }}>
            <CountDown />
          </div> */}
        {/* )} */}
      </div>
      <div id="result">{<Result drink={result?.drink} />}</div>
    </>
  );
}

export default Detection;
