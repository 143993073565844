import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie";
import { connect } from "react-redux";
import Webcam from "react-webcam";
import Loader from "../../Component/Loader";
import CustomWebcam from "../../Component/WebcamComponent";
import {
  ExistingRegistrationAction,
  GetRegistrationDataAction,
  RegistrationAction,
} from "../../Redux/Actions/RegistrationAction";
import hp from "../../assets/HP.svg";
import intel from "../../assets/Intel.svg";
import proceed from "../../assets/Proceed.svg";
import retake from "../../assets/Retake.svg";
import TickAnimation from "../../assets/Tick.json";
import win11 from "../../assets/Win11.svg";
import Z from "../../assets/Z.svg";
import bg from "../../assets/bg.jpg";
import camBtn from "../../assets/cam-btn.svg";
import camOutline from "../../assets/scan.png";
import { convertBase64ToFile, convertWebPToPng } from "../../utils";
import { toast, ToastContainer } from "react-toastify";
import "./Registration.css";
import { Autocomplete, InputAdornment, Paper, TextField } from "@mui/material";

// Define fonts as constants
const nameFont = "145px Forma";
const companyFont = "145px Forma";

function Registration({
  RegistrationAction,
  GetRegistrationList,
  ExistingRegistrationAction,
  GetRegistrationDataAction,
}) {
  const [step, setStep] = useState(0);
  const canvasRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    organization: "",
    designation: "",
    city: "",
  });
  const [capturedImage, setCapturedImage] = useState(null);
  const webcamRef = useRef(null);
  const [showZImage, setShowZImage] = useState(false);
  const [showTickAnimation, setShowTickAnimation] = useState(true);
  const [options, setOptions] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);

  const handleSearch = (value) => {
    if (value) {
      const filteredOptions = GetRegistrationList.filter((user) =>
        user.name.toLowerCase().includes(value.toLowerCase()),
      ).map((user) => ({
        key: user.phone,
        value: `${user.name} (${user.phone})`,
        ...user,
      }));

      setOptions(filteredOptions);
    } else {
      setOptions([]);
    }
  };

  const handleContactChange = (event, value) => {
    setSelectedContact(value);
    setFormData({
      name: value.name,
      email: value.email,
      phone: value.phone,
      organization: value.organization,
      designation: value.designation,
      city: value.city,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleContinueExistingUser = (e) => {
    e.preventDefault();
    if (selectedContact) {
      setFormData({
        name: selectedContact.name,
        email: selectedContact.email,
        phone: selectedContact.phone,
        organization: selectedContact.organization,
        designation: selectedContact.designation,
        city: selectedContact.city,
      });
    }
    if (step === 2.1) setStep(2.2);
  };

  const handleContinue = (e) => {
    e.preventDefault();

    // Check if all fields are filled
    const isFormValid = Object.values(formData).every(
      (value) => value.trim() !== "",
    );

    if (!isFormValid) {
      toast.error("Please fill all the details to continue");
      return;
    }

    // Proceed to the next step
    if (step === 1.1) setStep(1.2);
    if (step === 2.1) setStep(2.2);
  };

  const handleVerify = (e) => {
    e.preventDefault();
    if (step === 1.2) setStep(1.3);
    if (step === 2.2) setStep(2.3);
  };

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
  };

  const retakeImage = () => {
    setCapturedImage(null);
  };

  const handleProceed = () => {
    if (step === 1.2) setStep(1.3);
    if (step === 2.3) setStep(2.4);
    if (step === 1.3 || step === 2.4) setStep(3.0);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const pngBase64 = await convertWebPToPng(capturedImage);
      const imageFile = convertBase64ToFile(pngBase64, "capturedImage.png");

      // Use selectedContact data if available
      const dataToSubmit = selectedContact
        ? { ...selectedContact, image: imageFile }
        : { ...formData, image: imageFile };

      if (imageFile) {
        const response = await RegistrationAction(dataToSubmit);

        if (response.result === "Success") {
          setStep(3.0);
        } else {
          toast.error("Registration failed:", response.message);
        }
      } else {
        toast.error("Failed to convert captured image to file");
      }
    } catch (error) {
      if (error.response.data.errors[0].message) {
        toast.error("Please ensure your face is clearly visible in the image");
      } else {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleExistingSubmit = async () => {
    setLoading(true);

    try {
      const pngBase64 = await convertWebPToPng(capturedImage);
      const imageFile = convertBase64ToFile(pngBase64, "capturedImage.png");

      const dataToSubmit = {
        ...selectedContact,
        ...formData,
        image: imageFile,
        userId: selectedContact ? selectedContact._id : undefined,
      };

      console.log("Updated Form Data before submit:", formData);

      if (imageFile) {
        await ExistingRegistrationAction(dataToSubmit);
        setStep(4);
      } else {
        console.error("Failed to convert captured image to file");
      }
    } catch (error) {
      toast.error(error.response.data.errors[0].message);
    } finally {
      setLoading(false);
    }
  };

  const loadFont = async (fontName, fontUrl) => {
    try {
      const font = new FontFace(fontName, `url(${fontUrl})`);
      await font.load();
      document.fonts.add(font);
    } catch (error) {
      console.error(`Error loading font ${fontName}:`, error);
    }
  };

  const handlePrint = async (e) => {
    e.preventDefault();

    // Load the custom font from an absolute URL
    await loadFont(
      "Forma",
      "https://data-storage-tagglabs.s3.ap-south-1.amazonaws.com/formadjrdisplay-bold.ttf",
    );

    // Ensure formData contains name and organization
    if (!formData.name || !formData.organization) {
      console.error("formData is missing required fields");
      return;
    }

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    canvas.width = 1050;
    canvas.height = 1500;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const centerX = canvas.width / 2;

    ctx.font = `${nameFont}`;
    ctx.fillStyle = "#000000";
    ctx.textAlign = "center";
    ctx.fillText(formData.name, centerX, 1000);

    ctx.font = `${companyFont}`;
    ctx.fillText(formData.organization, centerX, 1200);

    const dataURL = canvas.toDataURL("image/png");

    const printImage = new Image();
    printImage.src = dataURL;
    printImage.id = "section-to-print";

    printImage.onload = function () {
      const printIframe = document.createElement("iframe");
      printIframe.style.visibility = "hidden";
      printIframe.style.position = "absolute";
      printIframe.style.width = "0";
      printIframe.style.height = "0";
      document.body.appendChild(printIframe);

      printIframe.contentDocument.write(`
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Print Document</title>
        <style>
      @media print {
        body {
          visibility: hidden;
        }
        #section-to-print {
          visibility: visible;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
        </style>
      </head>
      <body>
        <img src="${printImage.src}" id="section-to-print"/>
      </body>
      </html>
    `);
      printIframe.contentDocument.close();

      printIframe.contentWindow.focus();
      printIframe.contentWindow.print();

      setTimeout(() => {
        document.body.removeChild(printIframe);
      }, 100);
    };
  };

  useEffect(() => {
    document.getElementsByTagName(
      "body",
    )[0].style.backgroundImage = `url(${bg})`;
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await GetRegistrationDataAction();
      } catch (error) {
        toast.error("Failed to Fetch User Data. Please Check your Network.");
      }
    };
    fetchData();
  }, []);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: TickAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (step === 3.0) {
      const timer = setTimeout(() => {
        setShowTickAnimation(false);
        setShowZImage(true);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [step]);

  return (
    <div className="d-flex wh-100 flex-column">
      <ToastContainer />
      {loading && (
        <div
          className="wh-100 d-flex"
          style={{
            position: "absolute",
            zIndex: "9999",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          }}
        >
          <Loader />
        </div>
      )}
      {step === 0 && (
        <>
          <div className="d-flex">
            <img src={hp} alt="" id="hp" />
            <img src={intel} alt="" id="intel" />
            <div
              style={{
                width: "4px",
                height: "61.781px",
                backgroundColor: "#FFF",
              }}
            ></div>
            <img src={win11} alt="" />
          </div>
          <div className="big-switch">Big Switch 2024</div>
          <div className="shaping-future">Shaping the Future</div>
          <div className="btn-container">
            <button className="btn" onClick={() => setStep(1.1)}>
              New User
            </button>
            <button className="btn" onClick={() => setStep(2.1)}>
              Existing User
            </button>
          </div>
        </>
      )}

      {step === 1.1 && (
        <>
          <div
            className="d-flex"
            onClick={() => {
              window.location.reload();
            }}
          >
            <img src={hp} alt="" id="hp2" />
            <img src={intel} alt="" id="intel2" />
            <div
              style={{
                width: "1px",
                height: "39.781px",
                strokeWidth: "0.642px",
                backgroundColor: "#FFF",
              }}
            ></div>
            <img src={win11} alt="" id="win11-2" />
          </div>
          <div className="card">
            <h1 style={{ marginTop: "1em" }}>Registration</h1>
            <form action="" onSubmit={handleContinue}>
              <div className="d-flex mt-4">
                <div className="input-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter your name here"
                  />
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email here"
                  />
                  <label htmlFor="contactNumber">Contact Number</label>
                  <input
                    type="tel"
                    maxLength={10}
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Enter your contact number here"
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="organization">Organization</label>
                  <input
                    type="text"
                    name="organization"
                    value={formData.organization}
                    onChange={handleChange}
                    placeholder="Enter your organization here"
                  />
                  <label htmlFor="designation">Designation</label>
                  <input
                    type="text"
                    name="designation"
                    value={formData.designation}
                    onChange={handleChange}
                    placeholder="Enter your designation here"
                  />
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    placeholder="Enter your city name here"
                  />
                </div>
              </div>
              <div className="btn-container d-flex">
                <button className="btn" type="submit">
                  Continue
                </button>
              </div>
            </form>
          </div>
        </>
      )}

      {step === 1.2 && (
        <>
          <div className="d-flex wh-100 position-relative">
            {capturedImage ? (
              <div
                className="d-flex flex-column"
                onClick={() => {
                  window.location.reload();
                }}
              >
                <div className="d-flex">
                  <img src={hp} alt="" id="hp2" />
                  <img src={intel} alt="" id="intel2" />
                  <div
                    style={{
                      width: "1px",
                      height: "39.781px",
                      strokeWidth: "0.642px",
                      backgroundColor: "#FFF",
                    }}
                  ></div>
                  <img src={win11} alt="" id="win11-2" />
                </div>
                <img src={capturedImage} alt="Captured" className="camera" />
              </div>
            ) : (
              <div className="d-flex flex-column">
                <div className="d-flex">
                  <img src={hp} alt="" id="hp2" />
                  <img src={intel} alt="" id="intel2" />
                  <div
                    style={{
                      width: "1px",
                      height: "39.781px",
                      strokeWidth: "0.642px",
                      backgroundColor: "#FFF",
                    }}
                  ></div>
                  <img src={win11} alt="" id="win11-2" />
                </div>
                <CustomWebcam height={676} width={960} webcamRef={webcamRef} />
              </div>
            )}

            {capturedImage ? (
              <div className="cam-btn" style={{ display: "flex", gap: "4em" }}>
                <img src={retake} alt="" onClick={retakeImage} />
                <img src={proceed} alt="" onClick={handleProceed} />
              </div>
            ) : (
              <>
                <img src={camOutline} alt="" style={{ position: "absolute" }} />
                <img
                  src={camBtn}
                  alt=""
                  className="cam-btn"
                  style={{ bottom: "7em" }}
                  onClick={captureImage}
                />
              </>
            )}
          </div>
        </>
      )}

      {step === 1.3 && (
        <>
          <div
            className="d-flex"
            style={{
              marginBottom: "7em",
            }}
            onClick={() => {
              window.location.reload();
            }}
          >
            <img src={hp} alt="" id="hp2" />
            <img src={intel} alt="" id="intel2" />
            <div
              style={{
                width: "1px",
                height: "39.781px",
                strokeWidth: "0.642px",
                backgroundColor: "#FFF",
              }}
            ></div>
            <img src={win11} alt="" id="win11-2" />
          </div>
          <div
            className="card d-flex flex-column"
            style={{
              width: "739px",
              height: "649px",
            }}
          >
            <div
              style={{
                width: "195.5px",
                height: "195.5px",
                flexShrink: 0,
                marginTop: "-10em",
                position: "relative",
              }}
            >
              <img
                src={capturedImage}
                alt="Captured"
                className="camera"
                style={{
                  borderRadius: "50%",
                  width: "100%",
                  objectFit: "cover",
                  height: "100%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setStep(1.2);
                  setCapturedImage(null);
                }}
              />
              <button
                style={{
                  position: "absolute",
                  bottom: "5px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "rgba(128, 128, 128, 0.9)",
                  color: "white",
                  border: "none",
                  fontFamily: "FormaRegular",
                  borderRadius: "5px",
                  fontSize: "14px",
                  padding: "2px 11px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setStep(1.2);
                  setCapturedImage(null);
                }}
              >
                Edit
              </button>
            </div>
            <div className="form-data-container">
              <div>
                <p>Name</p>
                <p>Email</p>
                <p>Contact Number</p>
                <p>Organization</p>
                <p>Designation</p>
                <p>City</p>
              </div>
              <div>
                <p>{formData.name}</p>
                <p>{formData.email}</p>
                <p>{formData.phone}</p>
                <p>{formData.organization}</p>
                <p>{formData.designation}</p>
                <p>{formData.city}</p>
              </div>
            </div>
            <button className="btn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </>
      )}

      {step === 2.1 && (
        <>
          <div className="d-flex" onClick={() => window.location.reload()}>
            <img src={hp} alt="" id="hp2" />
            <img src={intel} alt="" id="intel2" />
            <div
              style={{
                width: "1px",
                height: "39.781px",
                strokeWidth: "0.642px",
                backgroundColor: "#FFF",
              }}
            ></div>
            <img src={win11} alt="" id="win11-2" />
          </div>
          <div
            className="card d-flex flex-column"
            style={{ width: "940.241px", height: "710px", flexShrink: 0 }}
          >
            <h1>Search an Existing User</h1>
            <div style={{ width: "40%" }}>
              <label htmlFor="contactName">Name</label>
              <Autocomplete
                fullWidth
                options={options}
                style={{ color: "white", backgroundColor: "white" }}
                getOptionLabel={(option) => option.value}
                onInputChange={(_, value) => handleSearch(value)}
                onChange={handleContactChange}
                PaperComponent={(props) => (
                  <Paper
                    {...props}
                    style={{
                      color: "black",
                      backgroundColor: "white",
                      fontFamily: "FormaRegular",
                    }}
                  />
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="name"
                    label=""
                    variant="outlined"
                    placeholder="Enter your name here"
                    autoFocus
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end"></InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: { color: "white", opacity: "50%" },
                    }}
                  />
                )}
              />
            </div>
            <div className="btn-container d-flex">
              <button
                className="btn"
                type="primary"
                onClick={handleContinueExistingUser}
              >
                Continue
              </button>
            </div>
          </div>
        </>
      )}

      {step === 2.2 && (
        <>
          <div
            className="d-flex"
            style={{ marginBottom: "2em" }}
            onClick={() => window.location.reload()}
          >
            <img src={hp} alt="" id="hp2" />
            <img src={intel} alt="" id="intel2" />
            <div
              style={{
                width: "1px",
                height: "39.781px",
                strokeWidth: "0.642px",
                backgroundColor: "#FFF",
              }}
            ></div>
            <img src={win11} alt="" id="win11-2" />
          </div>
          <div className="card">
            <h1 style={{ marginTop: "1em" }}>Verify your Details</h1>
            <form action="" onSubmit={handleVerify}>
              <div className="d-flex mt-4">
                <div className="input-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter your name here"
                  />
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email here"
                  />
                  <label htmlFor="contactNumber">Contact Number</label>
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Enter your contact number here"
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="organization">Organization</label>
                  <input
                    type="text"
                    name="organization"
                    value={formData.organization}
                    onChange={handleChange}
                    placeholder="Enter your organization here"
                  />
                  <label htmlFor="designation">Designation</label>
                  <input
                    type="text"
                    name="designation"
                    value={formData.designation}
                    onChange={handleChange}
                    placeholder="Enter your designation here"
                  />
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    placeholder="Enter your city name here"
                  />
                </div>
              </div>
              <div className="btn-container d-flex">
                <button className="btn" type="submit">
                  Verify & Proceed
                </button>
              </div>
            </form>
          </div>
        </>
      )}

      {step === 2.3 && (
        <>
          <div className="d-flex wh-100 position-relative">
            {capturedImage ? (
              <div className="d-flex flex-column">
                <div className="d-flex">
                  <img src={hp} alt="" id="hp2" />
                  <img src={intel} alt="" id="intel2" />
                  <div
                    style={{
                      width: "1px",
                      height: "39.781px",
                      strokeWidth: "0.642px",
                      backgroundColor: "#FFF",
                    }}
                  ></div>
                  <img src={win11} alt="" id="win11-2" />
                </div>
                <img
                  src={capturedImage}
                  alt="Captured"
                  className="camera"
                  style={{}}
                />
              </div>
            ) : (
              <div className="d-flex flex-column">
                Registration{" "}
                <div className="d-flex">
                  <img src={hp} alt="" id="hp2" />
                  <img src={intel} alt="" id="intel2" />
                  <div
                    style={{
                      width: "1px",
                      height: "39.781px",
                      strokeWidth: "0.642px",
                      backgroundColor: "#FFF",
                    }}
                  ></div>
                  <img src={win11} alt="" id="win11-2" />
                </div>
                <Webcam
                  className="camera"
                  height={676}
                  width={960}
                  ref={webcamRef}
                />
              </div>
            )}

            {capturedImage ? (
              <div className="cam-btn" style={{ display: "flex", gap: "4em" }}>
                <img src={retake} alt="" onClick={retakeImage} />
                <img src={proceed} alt="" onClick={handleProceed} />
              </div>
            ) : (
              <>
                <img src={camOutline} alt="" style={{ position: "absolute" }} />
                <img
                  src={camBtn}
                  alt=""
                  className="cam-btn"
                  onClick={captureImage}
                />
              </>
            )}
          </div>
        </>
      )}

      {step === 2.4 && (
        <>
          <div
            className="d-flex"
            style={{
              marginBottom: "8em",
            }}
          >
            <img src={hp} alt="" id="hp2" />
            <img src={intel} alt="" id="intel2" />
            <div
              style={{
                width: "1px",
                height: "39.781px",
                strokeWidth: "0.642px",
                backgroundColor: "#FFF",
              }}
            ></div>
            <img src={win11} alt="" id="win11-2" />
          </div>
          <div
            className="card d-flex flex-column"
            style={{
              width: "739px",
              height: "679px",
            }}
          >
            <div
              style={{
                width: "195.5px",
                height: "195.5px",
                flexShrink: 0,
                marginTop: "-10em",
              }}
            >
              <img
                src={capturedImage}
                alt="Captured"
                className="camera"
                style={{
                  borderRadius: "50%",
                  width: "100%",
                  objectFit: "cover",
                  height: "100%",
                }}
              />
            </div>
            <div className="form-data-container">
              <div>
                <p>Name</p>
                <p>Email</p>
                <p>Contact Number</p>
                <p>Organization</p>
                <p>Designation</p>
                <p>City</p>
              </div>
              <div>
                <p>{formData.name}</p>
                <p>{formData.email}</p>
                <p>{formData.phone}</p>
                <p>{formData.organization}</p>
                <p>{formData.designation}</p>
                <p>{formData.city}</p>
              </div>
            </div>
            <button className="btn" onClick={handleExistingSubmit}>
              Submit
            </button>
          </div>
        </>
      )}
      {step === 3.0 && (
        <>
          <div
            className="d-flex"
            onClick={() => {
              window.location.reload();
            }}
          >
            <img src={hp} alt="" id="hp" />
            <img src={intel} alt="" id="intel" />
            <div
              style={{
                width: "1px",
                height: "61.781px",
                backgroundColor: "#FFF",
              }}
            ></div>
            <img src={win11} alt="" />
          </div>
          <div
            className="d-flex flex-column"
            style={{ marginBottom: "8em", gap: "2em" }}
          >
            {showTickAnimation && (
              <>
                <Lottie options={defaultOptions} height={200} width={200} />
                <h1>Thank You for Your Registration!</h1>
              </>
            )}
            {showZImage && (
              <>
                <img src={Z} alt="Z" />
                <h1>Thank You for Your Registration!</h1>
                <button className="btn" onClick={(e) => handlePrint(e)}>
                  Print
                </button>
              </>
            )}
          </div>
        </>
      )}
      {step === 4.0 && (
        <>
          <div
            className="d-flex"
            onClick={() => {
              window.location.reload();
            }}
          >
            <img src={hp} alt="" id="hp" />
            <img src={intel} alt="" id="intel" />
            <div
              style={{
                width: "1px",
                height: "61.781px",
                backgroundColor: "#FFF",
              }}
            ></div>
            <img src={win11} alt="" />
          </div>
          <div
            className="d-flex flex-column"
            style={{ marginBottom: "8em", gap: "2em" }}
          >
            {showTickAnimation && (
              <>
                <Lottie options={defaultOptions} height={200} width={200} />
                <h1>Thank You for Your Registration!</h1>
              </>
            )}
            {showZImage && (
              <>
                <img src={Z} alt="Z" />
                <h1>Thank You for Your Registration!</h1>
              </>
            )}
          </div>
        </>
      )}
      <canvas ref={canvasRef} style={{ display: "none" }} />
    </div>
  );
}

Registration.propTypes = {
  RegistrationAction: PropTypes.func.isRequired,
  GetRegistrationDataAction: PropTypes.func.isRequired,
  ExistingRegistrationAction: PropTypes.func.isRequired,
  GetRegistrationList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  GetRegistrationList: state.RegistrationState.GetRegistrationList,
});

export default connect(mapStateToProps, {
  RegistrationAction,
  GetRegistrationDataAction,
  ExistingRegistrationAction,
})(Registration);
