import axios from "axios";

// #region FeedbackService
export const FeedbackService = async (data) => {
  try {
    // Url
    const url = `${import.meta.env.VITE_BASE_API}/user/feedback`;

    // Config
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const body = {
      userId: data.userID,
      feedback: data.feedback,
      isOptedLitrature: data.isOptedLiterature,
      litratureRequested: data.litratureRequested,
      type: data.type,
      date: data.date,
      time: data.time,
    };
    await axios.post(url, body, config);
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};
// #endregion
