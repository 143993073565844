import axios from "axios";

// #region RecognitionService
export const RecognitionService = async (data) => {
  try {
    // Url
    const url = `${import.meta.env.VITE_BASE_API}/user/recognise`;

    // Config
    const config = {
      headers: {
        "Content-type": "multipart/form-data",
      },
    };

    const body = {
      image: data.image,
      type: data.type,
    };
    const result = await axios.post(url, body, config);

    return result.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
// #endregion
