import React from "react";
import happy1 from "../../../assets/HAPPY/happy-1.gif";
import happy2 from "../../../assets/HAPPY/happy-2.gif";
import happy3 from "../../../assets/HAPPY/happy-3.gif";
import happy4 from "../../../assets/HAPPY/happy-4.gif";
import happy5 from "../../../assets/HAPPY/happy-5.gif";
import happy6 from "../../../assets/HAPPY/happy-6.gif";
import sad1 from "../../../assets/SAD/sad-1.gif";
import sad2 from "../../../assets/SAD/sad-2.gif";
import sad3 from "../../../assets/SAD/sad-3.gif";
import sad4 from "../../../assets/SAD/sad-4.gif";
import sad5 from "../../../assets/SAD/sad-5.gif";
import sad6 from "../../../assets/SAD/sad-6.gif";
import sad7 from "../../../assets/SAD/sad-7.gif";
import angry1 from "../../../assets/ANGRY/angry-1.gif";
import angry2 from "../../../assets/ANGRY/angry-2.gif";
import angry3 from "../../../assets/ANGRY/angry-3.gif";
import calm1 from "../../../assets/NEUTRAL/neutral-1.gif";
import calm2 from "../../../assets/NEUTRAL/neutral-2.gif";
import calm3 from "../../../assets/NEUTRAL/neutral-3.gif";
import surprise1 from "../../../assets/SURPRISE/surprise-1.gif";
import surprise2 from "../../../assets/SURPRISE/surprise-2.gif";
import surprise3 from "../../../assets/SURPRISE/surprise-3.gif";
import surprise4 from "../../../assets/SURPRISE/surprise-4.gif";
import surprise5 from "../../../assets/SURPRISE/surprise-5.gif";
import fear1 from "../../../assets/FEAR/fear-1.gif";
import fear2 from "../../../assets/FEAR/fear-2.gif";
import fear3 from "../../../assets/FEAR/fear-3.gif";
import disgust1 from "../../../assets/DISGUST/disgust-1.gif";
import disgust2 from "../../../assets/DISGUST/disgust-2.gif";
import disgust3 from "../../../assets/DISGUST/disgust-3.gif";
import disgust4 from "../../../assets/DISGUST/disgust-4.gif";

function MoodResult({ result }) {
  console.log(result);
  return (
    <>
      {result.mood === "HAPPY" && (
        <>
          {result.value === 1 && (
            <img src={happy1} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 2 && (
            <img src={happy2} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 3 && (
            <img src={happy3} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 4 && (
            <img src={happy4} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 5 && (
            <img src={happy5} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 6 && (
            <img src={happy6} alt="Happy" style={{ height: "100%" }} />
          )}
        </>
      )}
      {result.mood === "SAD" && (
        <>
          {result.value === 1 && (
            <img src={sad1} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 2 && (
            <img src={sad2} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 3 && (
            <img src={sad3} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 4 && (
            <img src={sad4} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 5 && (
            <img src={sad5} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 6 && (
            <img src={sad6} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 7 && (
            <img src={sad7} alt="Happy" style={{ height: "100%" }} />
          )}
        </>
      )}
      {result.mood === "ANGRY" && (
        <>
          {result.value === 1 && (
            <img src={angry1} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 2 && (
            <img src={angry2} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 3 && (
            <img src={angry3} alt="Happy" style={{ height: "100%" }} />
          )}
        </>
      )}
      {result.mood === "CALM" && (
        <>
          {result.value === 1 && (
            <img src={calm1} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 2 && (
            <img src={calm2} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 3 && (
            <img src={calm3} alt="Happy" style={{ height: "100%" }} />
          )}
        </>
      )}
      {result.mood === "SURPRISED" && (
        <>
          {result.value === 1 && (
            <img src={surprise1} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 2 && (
            <img src={surprise2} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 3 && (
            <img src={surprise3} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 4 && (
            <img src={surprise4} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 5 && (
            <img src={surprise5} alt="Happy" style={{ height: "100%" }} />
          )}
        </>
      )}
      {result.mood === "FEAR" && (
        <>
          {result.value === 1 && (
            <img src={fear1} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 2 && (
            <img src={fear2} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 3 && (
            <img src={fear3} alt="Happy" style={{ height: "100%" }} />
          )}
        </>
      )}
      {result.mood === "DISGUSTED" && (
        <>
          {result.value === 1 && (
            <img src={disgust1} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 2 && (
            <img src={disgust2} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 3 && (
            <img src={disgust3} alt="Happy" style={{ height: "100%" }} />
          )}
          {result.value === 4 && (
            <img src={disgust4} alt="Happy" style={{ height: "100%" }} />
          )}
        </>
      )}
    </>
  );
}

export default MoodResult;
