import { useState } from "react";
import ConfigAndDeploy from "./ConfigAndDeploy";
import "./ConfigAndDeployHome.css";
import homeImage from "../../assets/configandDeployHome.jpg";

export default function Config() {
  const [showConfig, setShowConfig] = useState(false);

  const handleButtonClick = () => {
    setShowConfig(true);
  };

  return (
    <div className="intro-container">
      {showConfig ? (
        <ConfigAndDeploy setShowConfig={setShowConfig} />
      ) : (
        <div
          className="intro-screen"
          style={{ backgroundImage: `url(${homeImage})` }}
        >
          <button
            className="intro-button"
            onClick={handleButtonClick}
            onTouchStart={handleButtonClick}
          >
            Tap to explore
          </button>
        </div>
      )}
    </div>
  );
}
