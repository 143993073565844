import { useState, useRef } from "react";
import PropTypes from "prop-types";
import "./ConfigAndDeploy.css";
import soundEffect from "../../assets/interface-sound.mp3";
import home from "../../assets/home.svg";

const videos = [
  "https://data-storage-tagglabs.s3.ap-south-1.amazonaws.com/hp-pdf/hp-bigswitch-video/Imaging.mp4",
  "https://data-storage-tagglabs.s3.ap-south-1.amazonaws.com/hp-pdf/hp-bigswitch-video/Hardware+Configuration.mp4",
  "https://data-storage-tagglabs.s3.ap-south-1.amazonaws.com/hp-pdf/hp-bigswitch-video/Tagging.mp4",
  "https://data-storage-tagglabs.s3.ap-south-1.amazonaws.com/hp-pdf/hp-bigswitch-video/Packaging.mp4",
  "https://data-storage-tagglabs.s3.ap-south-1.amazonaws.com/hp-pdf/hp-bigswitch-video/Delivery.mp4",
  "https://data-storage-tagglabs.s3.ap-south-1.amazonaws.com/hp-pdf/hp-bigswitch-video/Installation.mp4",
];

export default function ConfigAndDeploy({ setShowConfig }) {
  const [currentStep, setCurrentStep] = useState(0);
  const videoRef = useRef(null);
  const audioRef = useRef(null);

  const handleStepClick = (step) => {
    setCurrentStep(step + 1);
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const handleHomeClick = () => {
    setShowConfig(false);
  };

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  return (
    <div className="config-main-container">
      <audio ref={audioRef} src={soundEffect} preload="auto"></audio>
      <div className="config-video-container">
        {currentStep > 0 && (
          <video
            ref={videoRef}
            src={videos[currentStep - 1]}
            autoPlay
            muted
            playsInline
            onClick={handleVideoClick}
          ></video>
        )}
      </div>
      <div className="config-videobar-container">
        <div className="container">
          <div className="progress">
            <div
              className="percent"
              style={{ width: `${currentStep * 20}%` }}
            ></div>
          </div>
          <div className="steps">
            {[0, 1, 2, 3, 4, 5].map((step) => (
              <div key={step} className="step-container">
                <div
                  id={step}
                  className={`step ${step === currentStep ? "selected" : ""} ${
                    step < currentStep ? "completed" : ""
                  }`}
                  onClick={() => handleStepClick(step)}
                ></div>
              </div>
            ))}
          </div>
          <div className="steps-texts">
            <div>Imaging</div>
            <div>
              Hardware <br />
              Configuration
            </div>
            <div>Tagging</div>
            <div>Packaging</div>
            <div>Delivery</div>
            <div>Installation</div>
          </div>
        </div>
      </div>
      <div className="config-home-button">
        <img src={home} alt="home" onClick={handleHomeClick} />
      </div>
    </div>
  );
}

// Define prop types for the component
ConfigAndDeploy.propTypes = {
  setShowConfig: PropTypes.func.isRequired,
};
