import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import camBtn from "../../assets/cam-btn.svg";
import hp from "../../assets/HP.svg";
import intel from "../../assets/Intel.svg";
import proceed from "../../assets/Proceed.svg";
import retake from "../../assets/Retake.svg";
import camOutline from "../../assets/scan.png";
import win11 from "../../assets/Win11.svg";
import Z from "../../assets/Z.svg";
import FeedbackZone from "../../Component/FeedbackZone";
import Loader from "../../Component/Loader";
import CustomWebcam from "../../Component/WebcamComponent";
import { FeedbackAction } from "../../Redux/Actions/FeedbackAction";
import { RecognitionAction } from "../../Redux/Actions/RecognitionAction";
import { convertBase64ToFile, convertWebPToPng } from "../../utils";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const questions = {
  ai: "How would you rate the AI zone experience?",
  home: "How would you rate the At-Home zone experience?",
  office: "How would you rate the In-Office zone experience?",
  onthego: "How would you rate the On-the-go zone experience?",
  sustainability: "How would you rate the Sustainability zone experience?",
  overall: "How would you rate the overall event experience?",
};

function Feedback({ RecognitionAction, FeedbackAction }) {
  const webcamRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialZone = queryParams.get("zone") || "overall";

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [userID, setUserID] = useState("");
  const [capturedImage, setCapturedImage] = useState(null);
  const [zone, setZone] = useState(initialZone);
  const [feedback, setFeedback] = useState(null);
  const [isOptedLiterature, setIsOptedLiterature] = useState(null);
  const [dateTime, setDateTime] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showProceedButton, setShowProceedButton] = useState(true); // New state

  const handleOptionsChange = (options) => {
    setSelectedOptions(options);
  };

  const question = questions[zone] || questions.overall;

  const handleNext = async () => {
    if (!feedback) {
      toast.error("Please rate the zone experience.");
      return;
    }

    setLoading(true);

    const typeMapping = {
      ai: 1,
      home: 2,
      office: 3,
      onthego: 4,
      sustainability: 5,
      overall: 6,
    };

    const adjustedOptions = selectedOptions.map((option) => option - 1);

    const optedLiterature =
      adjustedOptions.length > 0 &&
      adjustedOptions.some((option) => option >= 0);

    setIsOptedLiterature(optedLiterature);

    const payload = {
      feedback,
      isOptedLiterature: optedLiterature,
      userID,
      type: typeMapping[zone],
      litratureRequested: adjustedOptions,
    };

    if (zone === "overall" && dateTime) {
      const [date, time] = dateTime.split("T");
      payload.date = date;
      payload.time = time;
    }

    try {
      await FeedbackAction(payload);
      setStep(4);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast.error("Failed to submit feedback. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const retakeImage = () => {
    setCapturedImage(null);
  };

  const handleProceed = () => {
    setStep(2);
  };

  const captureImage = async () => {
    setLoading(true);
    setShowProceedButton(true);

    try {
      const imageSrc = webcamRef.current.getScreenshot();
      let imageFile;

      if (imageSrc.startsWith("data:image/webp")) {
        const pngBase64 = await convertWebPToPng(imageSrc);
        imageFile = convertBase64ToFile(pngBase64, "capturedImage.png");
      } else {
        imageFile = convertBase64ToFile(imageSrc, "capturedImage.png");
      }

      setCapturedImage(imageSrc);

      const payload = {
        image: imageFile,
        type: 1,
      };

      const result = await RecognitionAction(payload);

      // Handle the response
      if (result.response) {
        setShowProceedButton(false);
      } else {
        toast.success("User data verified successfully.");
        setUserID(result.result.userId);
        setName(result.result.name);
        setImageUrl(result.result.imageUrl);
      }
    } catch (error) {
      toast.error(error.response.data.errors[0].message);
      setShowProceedButton(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const zoneParam = params.get("zone");
    if (zoneParam) {
      setZone(zoneParam);
    }
  }, []);

  const handleFeedbackClick = () => {
    navigate(`/feedback?zone=${zone}`);
    setStep(1);
  };

  return (
    <div className="d-flex wh-100 flex-column">
      <ToastContainer />
      {loading && (
        <div
          className="wh-100 d-flex"
          style={{
            position: "absolute",
            zIndex: "9999",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          }}
        >
          <Loader />
        </div>
      )}
      {step === 0 && (
        <>
          <div
            className="d-flex"
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={hp} alt="" id="hp" />
            <img src={intel} alt="" id="intel" />
            <div
              style={{
                width: "4px",
                height: "61.781px",
                backgroundColor: "#FFF",
              }}
            ></div>
            <img src={win11} alt="" />
          </div>
          <div className="big-switch">Big Switch 2024</div>
          <div className="shaping-future">Shaping the Future</div>

          <div className="btn-container flex-column">
            <button className="btn" onClick={handleFeedbackClick}>
              Feedback
            </button>
          </div>
        </>
      )}
      {step === 1 && (
        <>
          <div className="d-flex wh-100 position-relative">
            {capturedImage ? (
              <div className="d-flex flex-column">
                <div className="d-flex" style={{ marginBottom: "2em" }}>
                  <img src={hp} alt="" id="hp2" />
                  <img src={intel} alt="" id="intel2" />
                  <div
                    style={{
                      width: "1px",
                      height: "39.781px",
                      strokeWidth: "0.642px",
                      backgroundColor: "#FFF",
                    }}
                  ></div>
                  <img src={win11} alt="" id="win11-2" />
                </div>
                <img
                  src={capturedImage}
                  alt="Captured"
                  className="camera"
                  style={{ width: "90%" }}
                />
              </div>
            ) : (
              <div className="d-flex flex-column">
                <div
                  className="d-flex"
                  style={{
                    marginBottom: "2em",
                    position: "absolute",
                    top: "2em",
                    zIndex: "9999",
                  }}
                  onClick={() => {
                    setStep(0);
                  }}
                >
                  <img src={hp} alt="" id="hp2" />
                  <img src={intel} alt="" id="intel2" />
                  <div
                    style={{
                      width: "1px",
                      height: "39.781px",
                      strokeWidth: "0.642px",
                      backgroundColor: "#FFF",
                    }}
                  ></div>
                  <img src={win11} alt="" id="win11-2" />
                </div>
                <CustomWebcam height={576} width={760} webcamRef={webcamRef} />
              </div>
            )}

            {capturedImage ? (
              <div
                className="cam-btn"
                style={{ display: "flex", gap: "4em", bottom: "3em" }}
              >
                {showProceedButton ? (
                  <img src={proceed} alt="" onClick={handleProceed} />
                ) : (
                  <img src={retake} alt="" onClick={retakeImage} />
                )}
              </div>
            ) : (
              <>
                <img
                  src={camOutline}
                  alt=""
                  style={{ position: "absolute", width: "60%" }}
                />
                <img
                  src={camBtn}
                  alt=""
                  className="cam-btn"
                  style={{
                    bottom: "3em",
                  }}
                  onClick={captureImage}
                />
              </>
            )}
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <div
            className="d-flex"
            onClick={() => {
              window.location.reload();
            }}
          >
            <img src={hp} alt="" id="hp2" />
            <img src={intel} alt="" id="intel2" />
            <div
              style={{
                width: "1px",
                height: "39.781px",
                strokeWidth: "0.642px",
                backgroundColor: "#FFF",
              }}
            ></div>
            <img src={win11} alt="" id="win11-2" />
          </div>
          <h1
            style={{
              marginBottom: "4em",
            }}
          >
            User data verified!
          </h1>
          <div
            className="card d-flex flex-column"
            style={{
              width: "650px",
              height: "400px",
              border: "1px solid #00BB00",
              marginBottom: "2em",
            }}
          >
            <div
              style={{
                width: "195.5px",
                height: "195.5px",
                marginTop: "-8em",
              }}
            >
              <img
                src={imageUrl}
                alt="Captured"
                className="camera"
                style={{
                  borderRadius: "50%",
                  border: "3px solid #00BB00",
                  width: "100%",
                  objectFit: "cover",
                  height: "100%",
                }}
              />
            </div>
            <div
              className="input-group"
              style={{
                width: "70%",
                display: "flex",
                justifyContent: "center",
                height: "70%",
              }}
            >
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                value={name}
                placeholder="Enter your name here"
              />
            </div>
          </div>
          <button className="btn" type="submit" onClick={() => setStep(3)}>
            Continue
          </button>
        </>
      )}

      {step === 3 && (
        <>
          <div
            className="d-flex"
            onClick={() => {
              window.location.reload();
            }}
          >
            <img src={hp} alt="" id="hp2" />
            <img src={intel} alt="" id="intel2" />
            <div
              style={{
                width: "1px",
                height: "39.781px",
                strokeWidth: "0.642px",
                backgroundColor: "#FFF",
              }}
            ></div>
            <img src={win11} alt="" id="win11-2" />
          </div>
          <h1 style={{ marginBottom: "0em" }}>Feedback Form</h1>
          <FeedbackZone
            question={question}
            onFeedbackChange={(value) => setFeedback(value)}
            onLiteratureOptChange={(value) => setIsOptedLiterature(value)}
            onSubmit={handleNext}
            zone={zone}
            onOptionsChange={handleOptionsChange}
            onDateTimeChange={(value) => setDateTime(value)}
          />
        </>
      )}
      {step === 4 && (
        <>
          <div className="d-flex">
            <img src={hp} alt="" id="hp" />
            <img src={intel} alt="" id="intel" />
            <div
              style={{
                width: "1px",
                height: "61.781px",
                backgroundColor: "#FFF",
              }}
            ></div>
            <img src={win11} alt="" />
          </div>
          <div
            className="d-flex flex-column"
            style={{ marginBottom: "8em", gap: "2em" }}
          >
            <>
              <img src={Z} alt="Z" />
              <h1>Thank You for Your Feedback!</h1>
            </>
          </div>
        </>
      )}
    </div>
  );
}

Feedback.propTypes = {
  RecognitionAction: PropTypes.func.isRequired,
  FeedbackAction: PropTypes.func.isRequired,
};

export default connect(null, {
  RecognitionAction,
  FeedbackAction,
})(Feedback);
