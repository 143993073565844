import { createClient } from "@supabase/supabase-js";
export const convertBase64ToFile = (base64Image, fileName) => {
  try {
    const base64String = base64Image.split(";base64,").pop(); // Extract the base64 string
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const match = base64Image.match(
      /^data:image\/(png|jpeg|jpg|gif|webp);base64,/,
    );
    if (!match) {
      throw new Error("Invalid base64 image format");
    }

    const fileType = match[1];
    const blob = new Blob(byteArrays, { type: `image/${fileType}` });

    return new File([blob], fileName, { type: `image/${fileType}` });
  } catch (error) {
    console.error("Error converting base64 to file:", error);
    return null;
  }
};

export const colorArray = [
  // Whites
  "white",
  "ivory",
  "beige",
  "antiquewhite",

  // Yellows
  "yellow",
  "gold",
  "wheat",
  "khaki",
  "moccasin",
  "bisque",

  // Oranges
  "orange",
  "peach",
  "coral",

  // Reds
  "red",
  "maroon",
  "crimson",

  // Pinks
  "pink",
  "fuchsia",
  "lavender",

  // Purples
  "purple",
  "indigo",
  "violet",

  // Blues
  "blue",
  "navy",
  "slategray",
  "aqua",
  "aquamarine",
  "azure",
  "cyan",
  "aliceblue",

  // Greens
  "green",
  "lime",
  "olive",
  "chartreuse",

  "turquoise",
  "teal",

  // Browns
  "brown",

  // Blacks and Grays
  "black",

  // Metallics
  "silver",
];
export const convertWebPToPng = (webPBase64) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = webPBase64;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      canvas.toBlob(() => {
        const pngBase64 = canvas.toDataURL("image/png");
        resolve(pngBase64);
      }, "image/png");
    };
    img.onerror = (err) => {
      reject(err);
    };
  });
};

export const GenerateRandomNumber = (startvalue, endvalue) => {
  // Ensure startvalue and endvalue are numbers
  startvalue = Number(startvalue);
  endvalue = Number(endvalue);

  // Generate a random number between startvalue and endvalue (inclusive)
  return Math.floor(Math.random() * (endvalue - startvalue + 1)) + startvalue;
};

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_KEY;
export const supabase = createClient(supabaseUrl, supabaseKey);
