import { useRef, useState } from "react";
import hp from "../../assets/HP.svg";
import intel from "../../assets/Intel.svg";
import win11 from "../../assets/Win11.svg";
import CustomWebcam from "../../Component/WebcamComponent";
import camBtn from "../../assets/cam-btn.svg";
import camOutline from "../../assets/scan.png";
import retake from "../../assets/Retake.svg";
import proceed from "../../assets/Proceed.svg";
import { RecognitionAction } from "../../Redux/Actions/RecognitionAction";
import { FeedbackAction } from "../../Redux/Actions/FeedbackAction";
import { convertBase64ToFile, convertWebPToPng } from "../../utils";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../Component/Loader";
import { useNavigate } from "react-router-dom";
function SketchStartView({ RecognitionAction }) {
  const [faceDetection, setFaceDetection] = useState(true);
  const [capturedImage, setCapturedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showProceedButton, setShowProceedButton] = useState(true);
  const [result, setResult] = useState();
  const navigate = useNavigate();

  const webcamRef = useRef(null);
  const captureImage = async () => {
    setLoading(true);
    setShowProceedButton(true);

    try {
      const imageSrc = webcamRef.current.getScreenshot();
      let imageFile;

      if (imageSrc.startsWith("data:image/webp")) {
        const pngBase64 = await convertWebPToPng(imageSrc);
        imageFile = convertBase64ToFile(pngBase64, "capturedImage.png");
      } else {
        imageFile = convertBase64ToFile(imageSrc, "capturedImage.png");
      }

      setCapturedImage(imageSrc);

      const payload = {
        image: imageFile,
        type: 1,
      };

      const result = await RecognitionAction(payload);
      setResult(result);

      // Handle the response
      if (result.response) {
        setShowProceedButton(false);
      } else {
        toast.success("User data verified successfully.");
      }
    } catch (error) {
      // Show a toast for unexpected errors
      toast.error(error.response.data.errors[0].message);
      setShowProceedButton(false);
    } finally {
      setLoading(false);
    }
  };
  const retakeImage = () => {
    setCapturedImage(null);
  };
  const handleProceed = () => {
    navigate("/sketch", {
      state: result,
    });
  };
  return (
    <div className="d-flex wh-100 flex-column">
      <ToastContainer />
      {loading && (
        <div
          className="wh-100 d-flex"
          style={{
            position: "absolute",
            zIndex: "9999",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          }}
        >
          <Loader />
        </div>
      )}
      {faceDetection ? (
        <>
          <div className="d-flex">
            <img src={hp} alt="" id="hp" />
            <img src={intel} alt="" id="intel" />
            <div
              style={{
                width: "4px",
                height: "61.781px",
                backgroundColor: "#FFF",
              }}
            ></div>
            <img src={win11} alt="" />
          </div>
          <div className="big-switch">Welcome to</div>
          <div className="shaping-future">Sketch to AI Gen</div>
          <div className="btn-container">
            <button
              className="btn"
              onClick={() => {
                setFaceDetection(false);
              }}
            >
              Start
            </button>
          </div>
        </>
      ) : (
        <div className="d-flex wh-100 position-relative">
          {capturedImage ? (
            <div className="d-flex flex-column">
              <div className="d-flex">
                <img src={hp} alt="" id="hp2" />
                <img src={intel} alt="" id="intel2" />
                <div
                  style={{
                    width: "1px",
                    height: "39.781px",
                    strokeWidth: "0.642px",
                    backgroundColor: "#FFF",
                  }}
                ></div>
                <img src={win11} alt="" id="win11-2" />
              </div>
              <img
                src={capturedImage}
                alt="Captured"
                className="camera"
                style={{}}
              />
            </div>
          ) : (
            <div className="d-flex flex-column">
              <div className="d-flex">
                <img src={hp} alt="" id="hp2" />
                <img src={intel} alt="" id="intel2" />
                <div
                  style={{
                    width: "1px",
                    height: "39.781px",
                    strokeWidth: "0.642px",
                    backgroundColor: "#FFF",
                  }}
                ></div>
                <img src={win11} alt="" id="win11-2" />
              </div>
              <CustomWebcam height={600} width={600} webcamRef={webcamRef} />
            </div>
          )}

          {capturedImage ? (
            <div
              className="cam-btn"
              style={{
                display: "flex",
                height: "50px",
                gap: "4em",
                bottom: "15%",
              }}
            >
              {showProceedButton ? (
                <img src={proceed} alt="" onClick={handleProceed} />
              ) : (
                <img src={retake} alt="" onClick={retakeImage} />
              )}
            </div>
          ) : (
            <>
              <img
                src={camOutline}
                alt=""
                style={{
                  position: "absolute",
                  width: "60%",
                }}
              />
              <img
                src={camBtn}
                alt=""
                className="cam-btn"
                style={{
                  width: "50px",
                  height: "50px",

                  bottom: "15%",
                }}
                onClick={captureImage}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}

SketchStartView.propTypes = {
  RecognitionAction: PropTypes.func.isRequired,
  FeedbackAction: PropTypes.func.isRequired,
};

export default connect(null, {
  RecognitionAction,
  FeedbackAction,
})(SketchStartView);
