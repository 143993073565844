import axios from "axios";
// #region RegistrationService
export const RegistrationService = async (data) => {
  try {
    // Url
    const url = `${import.meta.env.VITE_BASE_API}/user`;

    // Config
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const body = new FormData();
    body.append("name", data.name);
    body.append("organization", data.organization);
    body.append("email", data.email);
    body.append("designation", data.designation);
    body.append("phone", data.phone);
    body.append("city", data.city);
    body.append("image", data.image);

    const result = await axios.post(url, body, config);
    return result.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
// #endregion

// #region RegistrationService
export const GetRegistrationDataService = async () => {
  try {
    // Url
    const url = `${import.meta.env.VITE_BASE_API}/user`;

    // Config
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const result = await axios.get(url, config);

    return result.data.result;
  } catch (err) {
    return Promise.reject(err);
  }
};
// #endregion

// #region  Service
export const ExistingRegistrationService = async (data) => {
  try {
    // Url
    const url = `${import.meta.env.VITE_BASE_API}/user`;

    // Config
    const config = {
      headers: {
        "Content-type": "multipart/form-data",
      },
    };

    const body = new FormData();
    body.append("name", data.name);
    body.append("organization", data.organization);
    body.append("email", data.email);
    body.append("designation", data.designation);
    body.append("phone", data.phone);
    body.append("city", data.city);
    body.append("image", data.image);
    body.append("userId", data.userId);

    await axios.put(url, body, config);
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};
// #endregion
