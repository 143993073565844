import { GET_REGISTRATION_DATA, REGISTRATION_NEW_USER } from "../Actions/Types";

// Defining Initial State
const initialState = {
  RegistrationList: [],
  GetRegistrationList: [],
};

// Defining Reducer Function
export const RegistrationState = (state = initialState, action) => {
  // Destructuring Action
  const { type, payload } = action;

  switch (type) {
    // Add Registration Case
    case REGISTRATION_NEW_USER: {
      return {
        ...state,
        RegistrationList: payload,
      };
    }
    case GET_REGISTRATION_DATA: {
      return {
        ...state,
        GetRegistrationList: payload,
      };
    }
    // Default Case
    default: {
      return state;
    }
  }
};
