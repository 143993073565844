import { useState } from "react";
import { useNavigate } from "react-router-dom";
import hp from "../assets/HP.svg";
import intel from "../assets/Intel.svg";
import win11 from "../assets/Win11.svg";

function HomePage() {
  const navigate = useNavigate();
  const [zone, setZone] = useState("");

  const handleCheckin = () => {
    navigate("/registration");
  };

  const handleMood = () => {
    navigate("/mood");
  };

  const handleFeedback = (zone) => {
    if (zone && zone !== "feedbackPod") {
      navigate(`/feedback?zone=${zone}`);
    }
  };

  const handleSketch = () => {
    navigate("/sketch/startView");
  };

  const handleConfigDeploy = () => {
    navigate("/config");
  };

  const handleZoneChange = (e) => {
    const selectedZone = e.target.value;
    setZone(selectedZone);
    handleFeedback(selectedZone);
  };

  return (
    <div className="d-flex wh-100 flex-column">
      <div className="d-flex">
        <img src={hp} alt="" id="hp" />
        <img src={intel} alt="" id="intel" />
        <div
          style={{
            width: "4px",
            height: "61.781px",
            backgroundColor: "#FFF",
          }}
        ></div>
        <img src={win11} alt="" />
      </div>
      <div className="big-switch">Big Switch 2024</div>
      <div className="shaping-future">Shaping the Future</div>
      <div
        className="btn-container d-flex"
        style={{ display: "flex", width: "80%", flexWrap: "wrap" }}
      >
        <button className="btn" onClick={handleCheckin}>
          Registration
        </button>
        <button className="btn" onClick={handleMood}>
          Mood Detector
        </button>
        <div>
          <select className="btn" onChange={handleZoneChange} value={zone}>
            <option value="feedbackPod">Feedback Pod</option>
            <option value="ai">AI Zone</option>
            <option value="home">At-Home Zone</option>
            <option value="office">In-Office Zone</option>
            <option value="onthego">On-the-go Zone</option>
            <option value="sustainability">Sustainability Zone</option>
            <option value="overall">Overall Event</option>
          </select>
        </div>
        <button className="btn" onClick={handleSketch}>
          Sketch To AI
        </button>
        <button className="btn" onClick={handleConfigDeploy}>
          Config & Deploy
        </button>
        <button className="btn" onClick={() => navigate("/sketchToScreen")}>
          Sketch on Screen
        </button>
        <button className="btn" onClick={() => navigate("/getcertificate")}>
          Get Certificate
        </button>
      </div>
    </div>
  );
}

export default HomePage;
