import axios from "axios";

// #region RecognitionService
export const SketchToAiService = async (data) => {
  try {
    // Url
    const url = `${import.meta.env.VITE_BASE_API}/user/send-mail`;

    // Config
    const config = {
      headers: {
        "Content-type": "mutipart/form-data",
      },
    };

    const body = new FormData();
    body.append("userId", data.userid);
    body.append("genratedImage", data.image);
    body.append("orignalImage", data.userImage);

    const result = await axios.post(url, body, config);

    return result.data;
  } catch (err) {
    console.log(err.response.data.errors[0].message);
    return Promise.reject(err);
  }
};
// #endregion
