import { RecognitionService } from "../../Services/RecognitionService";
import { FACE_RECOGNITION } from "./Types";

//#region RecognitionAction
export const RecognitionAction = (data) => async (dispatch) => {
  try {
    // Calling Service
    const result = await RecognitionService(data);

    // Dispatching To Reducer
    dispatch({
      type: FACE_RECOGNITION,
      payload: result,
    });
    return result;
  } catch (err) {
    console.error("Error in RecognitionAction:", err);
    throw err;
  }
};
// #endregion
