import { FeedbackService } from "../../Services/FeedbackService";
import { USER_FEEDBACK } from "./Types";

//#region FeedbackAction
export const FeedbackAction = (data) => async (dispatch) => {
  try {
    // Calling Service
    const result = await FeedbackService(data);

    // Dispatching To Reducer
    dispatch({
      type: USER_FEEDBACK,
      payload: result,
    });
  } catch (err) {
    // Errors
    let errors = err.response.data.errors;

    if (errors !== undefined) {
      for (let x of errors) {
        // Errors Alert
        dispatch((x.msg, "error", 3000));
      }
    } else {
      return Promise.reject(err);
    }
  }
};
// #endregion
