import {
  ExistingRegistrationService,
  GetRegistrationDataService,
  RegistrationService,
} from "../../Services/RegistrationService.js";
import {
  EXISTING_REGISTRATION,
  GET_REGISTRATION_DATA,
  REGISTRATION_NEW_USER,
} from "./Types.js";

//#region RegistrationAction
export const RegistrationAction = (data) => async (dispatch) => {
  try {
    // Calling Service
    const result = await RegistrationService(data);

    // Dispatching To Reducer
    dispatch({
      type: REGISTRATION_NEW_USER,
      payload: result,
    });
    return result;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
// #endregion

//#region RegistrationAction
export const GetRegistrationDataAction = (data) => async (dispatch) => {
  try {
    // Calling Service
    const result = await GetRegistrationDataService(data);

    // Dispatching To Reducer
    dispatch({
      type: GET_REGISTRATION_DATA,
      payload: result,
    });
  } catch (err) {
    // Errors
    let errors = err.response.data.errors;

    if (errors !== undefined) {
      for (let x of errors) {
        // Errors Alert
        dispatch((x.msg, "error", 3000));
      }
    } else {
      return Promise.reject(err);
    }
  }
};
// #endregion

//#region RegistrationAction
export const ExistingRegistrationAction = (data) => async (dispatch) => {
  try {
    // Calling Service
    const result = await ExistingRegistrationService(data);

    // Dispatching To Reducer
    dispatch({
      type: EXISTING_REGISTRATION,
      payload: result,
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};
// #endregion
