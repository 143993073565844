import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Feedback from "./Pages/Feedback Pod/Feedback.jsx";
import HomePage from "./Pages/HomePage.jsx";
import StartView from "./Pages/MoodDetection/StartView/StartView.jsx";
import Registration from "./Pages/Registration/Registration.jsx";
import Detection from "./Pages/MoodDetection/Detection/Detection.jsx";
import Result from "./Pages/MoodDetection/Result.jsx";
import Canvas from "./Pages/SketchToAi/Sketch.jsx";
import SketchStartView from "./Pages/SketchToAi/SketchStartView.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfigAndDeploy from "./Pages/Config and Deploy/ConfigAndDeployHome.jsx";
import ResultScreen from "./Pages/SketchToAi/ResultScreen.jsx";
import Certificate from "./Pages/Certificate.jsx";

const App = () => {
  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/mood" element={<StartView />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/detect" element={<Detection />} />
        <Route path="/result" element={<Result />} />
        <Route path="/sketch" element={<Canvas />} />
        <Route path="/sketch/startView" element={<SketchStartView />} />
        <Route path="/config" element={<ConfigAndDeploy />} />
        <Route path="/sketchToScreen" element={<ResultScreen />} />
        <Route path="/getcertificate" element={<Certificate />} />
      </Routes>
    </Router>
  );
};

export default App;
