import Webcam from "react-webcam";
import PropTypes from "prop-types";

const CustomWebcam = ({ height, width, webcamRef }) => {
  return (
    <Webcam
      className="camera"
      height={height}
      width={width}
      ref={webcamRef}
      mirrored={true}
    />
  );
};

CustomWebcam.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  webcamRef: PropTypes.object.isRequired,
};

export default CustomWebcam;
