import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Bad from "../assets/Bad.svg";
import Neutral from "../assets/Neutral.svg";
import Great from "../assets/Great.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FeedbackZone = ({
  question,
  zone,
  onFeedbackChange,
  onLiteratureOptChange,
  onSubmit,
  onOptionsChange,
  onDateTimeChange, // Add this prop
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [feedbackSelected, setFeedbackSelected] = useState(false);
  const [dateTime, setDateTime] = useState(""); // New state for date and time

  useEffect(() => {
    setFeedbackSelected(selectedFeedback !== null);
  }, [selectedFeedback]);

  const handleOptionChange = (e) => {
    const value = e.target.value === "yes";
    setSelectedOption(value);
    onLiteratureOptChange(value);
  };

  const handleFeedbackClick = (value) => {
    setSelectedFeedback(value);
    onFeedbackChange(value);
  };

  const handleCheckboxChange = (option) => {
    const optionNumber = getOptionsByZone().indexOf(option) + 1;
    const updatedOptions = selectedOptions.includes(optionNumber)
      ? selectedOptions.filter((item) => item !== optionNumber)
      : [...selectedOptions, optionNumber];

    setSelectedOptions(updatedOptions);
    onOptionsChange(updatedOptions);
  };

  const handleDateTimeChange = (e) => {
    setDateTime(e.target.value);
    onDateTimeChange(e.target.value); // Pass the value up
  };

  const currentDate = new Date();
  const threeMonthsLater = new Date();
  threeMonthsLater.setMonth(currentDate.getMonth() + 3);

  const minDate = currentDate.toISOString().split("T")[0];
  const maxDate = threeMonthsLater.toISOString().split("T")[0];

  const getOptionsByZone = () => {
    switch (zone) {
      case "ai":
        return [
          "Checklist for Future of Work",
          "AI Solutions powered by Z by HP Workstations",
          "Data Science Stack manager",
        ];
      case "home":
        return ["Displays & Accessories", "HP Anyware", "Support Science"];
      case "office":
        return [
          "Managed Collaboration Services",
          "Managed Device Service",
          "Managed Print Service",
          "Proactive Insights",
          "Protect & Trace Security Solution",
        ];
      case "onthego":
        return [
          "Endpoint Security Solutions",
          "Engage Industrial Solutions Brochure",
        ];
      case "sustainability":
        return [
          "2023 SI Report Executive Summary",
          "Carbon Neutral Computing Solutions",
          "IT Asset Disposition Service",
          "Responsible Printing for a Better Planet Digital",
        ];
      default:
        return [];
    }
  };

  return (
    <div className="d-flex wh-100 flex-column">
      <ToastContainer />
      <div
        className="card d-flex flex-column"
        style={{ width: "760px", height: "580px", marginBottom: "1em" }}
      >
        <h1>{question}</h1>
        <div className="d-flex" style={{ gap: "6em", textAlign: "center" }}>
          <div onClick={() => handleFeedbackClick("bad")}>
            <img src={Bad} alt="Bad" />
            <p
              style={{
                cursor: "pointer",
                color: selectedFeedback === "bad" ? "#0096D6" : "white",
              }}
            >
              Bad
            </p>
          </div>
          <div onClick={() => handleFeedbackClick("neutral")}>
            <img src={Neutral} alt="Neutral" />
            <p
              style={{
                cursor: "pointer",
                color: selectedFeedback === "neutral" ? "#0096D6" : "white",
              }}
            >
              Neutral
            </p>
          </div>
          <div onClick={() => handleFeedbackClick("great")}>
            <img src={Great} alt="Great" />
            <p
              style={{
                cursor: "pointer",
                color: selectedFeedback === "great" ? "#0096D6" : "white",
              }}
            >
              Great
            </p>
          </div>
        </div>
        <h1>
          {zone === "overall"
            ? "To schedule a meeting with HP team, please select your preferred date and time"
            : "I’m interested to know more about"}
        </h1>
        {zone === "overall" && (
          <div className="d-flex" style={{ gap: "1em" }}>
            <input
              type="radio"
              id="yes"
              name="feedback"
              value="yes"
              onChange={handleOptionChange}
            />
            <p>Yes</p>
            <input
              type="radio"
              id="no"
              name="feedback"
              value="no"
              onChange={handleOptionChange}
            />
            <p>No</p>
            {selectedOption === true && (
              <div>
                <input
                  type="datetime-local"
                  id="datetime"
                  name="datetime"
                  min={`${minDate}T00:00`}
                  max={`${maxDate}T23:59`}
                  value={dateTime}
                  onChange={handleDateTimeChange}
                />
              </div>
            )}
          </div>
        )}
        {zone !== "overall" && (
          <div>
            {getOptionsByZone().map((option, index) => (
              <div
                key={index}
                className="d-flex"
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "2em",
                  paddingBottom: "1em",
                }}
              >
                <div>
                  <input
                    type="checkbox"
                    id={`option-${index}`}
                    name={option}
                    value={option}
                    checked={selectedOptions.includes(index + 1)}
                    onChange={() => handleCheckboxChange(option)}
                    className="large-checkbox"
                  />
                </div>
                <div
                  style={{
                    textAlign: "left",
                    width: "365px",
                  }}
                >
                  <label
                    style={{ fontSize: "21px" }}
                    htmlFor={`option-${index}`}
                  >
                    {option}
                  </label>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <button
        className="btn"
        type="submit"
        onClick={() => {
          if (!feedbackSelected) {
            toast.error("Please rate the zone experience.");
            return;
          }
          onSubmit(selectedFeedback, selectedOptions, dateTime);
        }}
      >
        Submit
      </button>
    </div>
  );
};

FeedbackZone.propTypes = {
  question: PropTypes.string.isRequired,
  onFeedbackChange: PropTypes.func.isRequired,
  onLiteratureOptChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  zone: PropTypes.string.isRequired,
  onOptionsChange: PropTypes.func.isRequired,
  onDateTimeChange: PropTypes.func.isRequired, // Add this prop type
};

export default FeedbackZone;
