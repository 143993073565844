import React, { useEffect } from "react";
import "./../style.css";
import hp from "../../../assets/HP.svg";
import intel from "../../../assets/Intel.svg";
import win11 from "../../../assets/Win11.svg";
import arrow from "../../../assets/arrow.svg";
import orange from "../../../assets/orangeglass.png";
import thirdDrink from "../../../assets/third-drink.png";
import glass from "../../../assets/glass.png";
import { useNavigate } from "react-router-dom";
function StartView() {
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/detect");
  };

  return (
    <div
      className="d-flex full-height dir-column"
      style={{
        backgroundImage: `url(${orange}), url(${thirdDrink}) , url(${glass})`,
        overflow: "hidden",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left ,bottom, 115% 30%",
        backgroundSize: "auto, auto, 400px",
      }}
    >
      <div className="d-flex">
        <img src={hp} alt="" id="hp" />
        <img src={intel} alt="" id="intel" />
        <div
          style={{
            width: "1px",
            height: "61.781px",
            backgroundColor: "#FFF",
          }}
        ></div>
        <img src={win11} alt="" />
      </div>
      <div className="main-container">
        <div>
          <div className="big-switch">Big Switch 2024</div>
          <div className="shaping-future">Shaping the Future</div>
        </div>
        <div>
          <button
            onClick={onClick}
            className="btn-2"
            style={{ marginTop: "50px" }}
          >
            Discover Your Drink <img src={arrow} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default StartView;
