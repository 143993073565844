import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../Component/Loader";
import hp from "../assets/HP.svg";
import intel from "../assets/Intel.svg";
import win11 from "../assets/Win11.svg";
import { useEffect, useRef, useState } from "react";
import QRCode from "qrcode";
import CustomWebcam from "../Component/WebcamComponent";
import camBtn from "../assets/cam-btn.svg";
import retake from "../assets/Retake.svg";
import camOutline from "../assets/scan.png";
import Z from "../assets/Z.svg";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TickAnimation from "../assets/Tick.json";
import { convertBase64ToFile, convertWebPToPng } from "../utils";
import { RecognitionAction } from "../Redux/Actions/RecognitionAction";
import "react-toastify/dist/ReactToastify.css";
import Lottie from "react-lottie";
import certificate from "../assets/Certificate.jpg";
import letter from "../assets/Letter.jpg";

function Certificate() {
  const webcamRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [capturedImage, setCapturedImage] = useState(null);
  const [showProceedButton, setShowProceedButton] = useState(true);
  const [userID, setUserID] = useState("");
  const [showZImage, setShowZImage] = useState(false);
  const [showTickAnimation, setShowTickAnimation] = useState(true);

  const retakeImage = () => {
    setCapturedImage(null);
  };

  async function drawImageWithQRCode(backgroundSrc, name, treeUrl, fileName) {
    return new Promise(async (resolve, reject) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();

      img.onload = async () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        // Draw text
        ctx.font = "20px FormaRegular";
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.fillText(name, canvas.width / 2, canvas.height / 2);

        // Generate and draw the QR code
        if (treeUrl) {
          try {
            console.log("Generating QR code for:", treeUrl); // Log URL for QR code

            const qrCodeDataURL = await QRCode.toDataURL(treeUrl, {
              margin: 1,
              color: {
                dark: "#000000", // QR code color
                light: "#0000", // Transparent background
              },
            });

            const qrCodeImg = new Image();

            qrCodeImg.onload = () => {
              const qrCodeSize = 100;
              const qrCodeX = (canvas.width - qrCodeSize) / 2;
              const qrCodeY = canvas.height / 2 + canvas.height / 4;

              ctx.drawImage(
                qrCodeImg,
                qrCodeX,
                qrCodeY,
                qrCodeSize,
                qrCodeSize,
              );

              canvas.toBlob((blob) => {
                const file = new File([blob], fileName, { type: "image/png" });
                resolve(file);
              }, "image/png");
            };

            qrCodeImg.onerror = (error) => reject(error);
            qrCodeImg.src = qrCodeDataURL;
          } catch (error) {
            reject(error);
          }
        } else {
          canvas.toBlob((blob) => {
            const file = new File([blob], fileName, { type: "image/png" });
            resolve(file);
          }, "image/png");
        }
      };

      img.onerror = (error) => reject(error);
      img.src = backgroundSrc;
    });
  }

  async function drawImageWithoutQRCode(name, fileName) {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        canvas.toBlob((blob) => {
          const file = new File([blob], fileName, { type: "image/png" });
          resolve(file);
        }, "image/png");
      };

      img.onerror = (error) => reject(error);
      img.src = letter; // Use the Letter.jpg image
    });
  }

  const captureImage = async () => {
    setLoading(true);
    setShowProceedButton(true);

    try {
      const imageSrc = webcamRef.current.getScreenshot();
      let imageFile;

      if (imageSrc.startsWith("data:image/webp")) {
        const pngBase64 = await convertWebPToPng(imageSrc);
        imageFile = convertBase64ToFile(pngBase64, "capturedImage.png");
      } else {
        imageFile = convertBase64ToFile(imageSrc, "capturedImage.png");
      }

      setCapturedImage(imageSrc);

      const recognisePayload = new FormData();
      recognisePayload.append("image", imageFile);
      recognisePayload.append("type", "5");

      const recogniseResult = await axios.post(
        `${import.meta.env.VITE_BASE_API}/user/recognise`,
        recognisePayload,
        { headers: { "Content-Type": "multipart/form-data" } },
      );

      console.log("Recognise API Result:", recogniseResult.data); // Verify response

      if (recogniseResult.data.response) {
        setStep(2);
      } else {
        const { userId, treeUrl, name } = recogniseResult.data.result;
        console.log("Extracted treeUrl:", treeUrl); // Verify URL

        setUserID(userId);

        let finalImageFile;
        if (treeUrl) {
          finalImageFile = await drawImageWithQRCode(
            certificate,
            name,
            treeUrl,
            "certificate.png",
          );
        } else {
          finalImageFile = await drawImageWithoutQRCode(
            certificate,
            name,
            "certificate.png",
          );
        }

        console.log(finalImageFile);

        const sendMailTreePayload = new FormData();
        sendMailTreePayload.append("image", finalImageFile);
        sendMailTreePayload.append("userId", String(userId));

        await axios.post(
          `${import.meta.env.VITE_BASE_API}/user/send-mail-tree`,
          sendMailTreePayload,
          { headers: { "Content-Type": "multipart/form-data" } },
        );

        setStep(2);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.errors?.[0]?.message || "An error occurred",
      );
      setShowProceedButton(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (step === 2) {
      const timer = setTimeout(() => {
        window.location.reload();
      }, 7000);

      return () => clearTimeout(timer);
    }
  }, [step]);

  useEffect(() => {
    if (step === 2) {
      const timer = setTimeout(() => {
        setShowTickAnimation(false);
        setShowZImage(true);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [step]);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: TickAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="d-flex wh-100 flex-column">
      <ToastContainer />
      {loading && (
        <div
          className="wh-100 d-flex"
          style={{
            position: "absolute",
            zIndex: "9999",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          }}
        >
          <Loader />
        </div>
      )}
      {step === 0 && (
        <>
          <div className="d-flex">
            <img src={hp} alt="" id="hp" />
            <img src={intel} alt="" id="intel" />
            <div
              style={{
                width: "4px",
                height: "61.781px",
                backgroundColor: "#FFF",
              }}
            ></div>
            <img src={win11} alt="" />
          </div>
          <div className="big-switch">Big Switch 2024</div>
          <div className="shaping-future">Shaping the Future</div>
          <div className="btn-container">
            <button className="btn" onClick={() => setStep(1)}>
              Certificate
            </button>
          </div>
        </>
      )}
      {step === 1 && (
        <>
          <div className="d-flex wh-100 position-relative">
            {capturedImage ? (
              <div className="d-flex flex-column">
                <div className="d-flex" style={{ marginBottom: "2em" }}>
                  <img src={hp} alt="" id="hp2" />
                  <img src={intel} alt="" id="intel2" />
                  <div
                    style={{
                      width: "1px",
                      height: "39.781px",
                      strokeWidth: "0.642px",
                      backgroundColor: "#FFF",
                    }}
                  ></div>
                  <img src={win11} alt="" id="win11-2" />
                </div>
                <img
                  src={capturedImage}
                  alt="Captured"
                  className="camera"
                  style={{ width: "90%" }}
                />
              </div>
            ) : (
              <div className="d-flex flex-column">
                <div
                  className="d-flex"
                  style={{
                    marginBottom: "2em",
                    position: "absolute",
                    top: "2em",
                    zIndex: "9999",
                  }}
                  onClick={() => {
                    setStep(0);
                  }}
                >
                  <img src={hp} alt="" id="hp2" />
                  <img src={intel} alt="" id="intel2" />
                  <div
                    style={{
                      width: "1px",
                      height: "39.781px",
                      strokeWidth: "0.642px",
                      backgroundColor: "#FFF",
                    }}
                  ></div>
                  <img src={win11} alt="" id="win11-2" />
                </div>
                <CustomWebcam height={576} width={760} webcamRef={webcamRef} />
              </div>
            )}

            {capturedImage ? (
              <div
                className="cam-btn"
                style={{ display: "flex", gap: "4em", bottom: "3em" }}
              >
                <img src={retake} alt="" onClick={retakeImage} />
              </div>
            ) : (
              <>
                <img
                  src={camOutline}
                  alt=""
                  style={{ position: "absolute", width: "60%" }}
                />
                <img
                  src={camBtn}
                  alt=""
                  className="cam-btn"
                  style={{
                    bottom: "3em",
                  }}
                  onClick={captureImage}
                />
              </>
            )}
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <div
            className="d-flex"
            onClick={() => {
              window.location.reload();
            }}
          >
            <img src={hp} alt="" id="hp" />
            <img src={intel} alt="" id="intel" />
            <div
              style={{
                width: "1px",
                height: "61.781px",
                backgroundColor: "#FFF",
              }}
            ></div>
            <img src={win11} alt="" />
          </div>
          <div
            className="d-flex flex-column"
            style={{ marginBottom: "8em", gap: "2em" }}
          >
            {showTickAnimation && (
              <>
                <Lottie options={defaultOptions} height={200} width={200} />
                <h1 style={{ lineHeight: "1.3em" }}>
                  Thank You for the Participation, <br />
                  You&apos;ll receive your mail shortly.
                </h1>
              </>
            )}
            {showZImage && (
              <>
                <img src={Z} alt="Z" />
                <h1 style={{ lineHeight: "1.3em" }}>
                  Thank You for the Participation, <br />
                  You&apos;ll receive your mail shortly.
                </h1>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

Certificate.propTypes = {
  RecognitionAction: PropTypes.func.isRequired,
};

export default connect(null, {
  RecognitionAction,
})(Certificate);
