import axios from "axios";
import { GenerateRandomNumber } from "../utils";
// #region RegistrationService
export const ExpressionDetectionService = async (data) => {
  try {
    // Url
    const url = `${import.meta.env.VITE_BASE_API}/user/recognise`;

    // Config
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const body = new FormData();

    body.append("image", data.image);
    body.append("type", "3");
    const Result = await axios.post(url, body, config);
    const result = Result.data?.result;

    // return Promise.resolve(result?.data)

    if (result?.mood === "HAPPY") {
      const value = GenerateRandomNumber(1, 6);
      return {
        ...result,
        value: value,
      };
    } else if (result?.mood === "SAD") {
      const value = GenerateRandomNumber(1, 7);
      return {
        ...result,
        value: value,
      };
    } else if (result?.mood === "ANGRY") {
      const value = GenerateRandomNumber(1, 3);
      return {
        ...result,
        value: value,
      };
    } else if (result?.mood === "CALM") {
      const value = GenerateRandomNumber(1, 3);
      return {
        ...result,
        value: value,
      };
    } else if (result?.mood === "SURPRISED") {
      const value = GenerateRandomNumber(1, 5);
      return {
        ...result,
        value: value,
      };
    } else if (result?.mood === "FEAR") {
      const value = GenerateRandomNumber(1, 3);
      return {
        ...result,
        value: value,
      };
    } else if (result?.mood === "DISGUSTED") {
      const value = GenerateRandomNumber(1, 4);
      return {
        ...result,
        value: value,
      };
    } else {
      const value = GenerateRandomNumber(1, 4);
      return {
        ...result,
        value: value,
        mood: "SURPRISED",
        drink: "Eureka Twist",
      };
    }
  } catch (err) {
    return Promise.reject(err);
  }
};
// #endregion
